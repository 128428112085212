<template>
  <div class="container" v-title :data-title="$t('i18n.partsMaintenance')">
    <div id="outer-title">{{ $t("i18n.partsMaintenance") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :height="$defined.HEIGHT - 360 + 'px'"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectPartData');
          }
        "
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.new')"
      v-model="newPartVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddPartList"
        :tableData="addPartData"
        :options="addPartOptions"
        :columns="addPartColumns"
        :operates="addPartOperates"
        :total="addPartTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.update')"
      v-model="dialogUpdateVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="updateColumns"
        @onSubmit="onSubmit"
        @resetForm="onCancel('dialogUpdateVisible')"
      ></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.historicalUpdate')"
      v-model="dialogHistoryVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-timeline v-if="updateHistory.length > 0">
        <el-timeline-item
          v-for="(activity, index) in updateHistory"
          :key="index"
          :timestamp="activity.timestamp"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div v-else>{{ $t("i18n.noHistoricalData") }}</div>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
import { useRoute } from "vue-router";
export default {
  name: "PartsMaintenance",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const { t } = useI18n();
    const state = reactive({
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
        },
        {
          prop: "name",
          label: "partName",
          align: "center",
        },
        {
          prop: "replaceCondition",
          label: "technicalReplacement",
          align: "left",
          width: 500,
          formatter: (row) => {
            if (row.replaceCondition.indexOf("\n") >= 0) {
              return row.replaceCondition.replace(/\n/g, "<br/>");
            } else {
              return row.replaceCondition;
            }
          },
        },
        {
          prop: "replacementCycle",
          label: "replacementCycle",
          align: "center",
        },
        {
          prop: "dischargeDate",
          label: "manufactureDate",
          align: "center",
          row: false,
        },
        {
          prop: "replacementDate",
          label: "updateDate",
          align: "center",
        },
        {
          prop: "renewalReason",
          label: "updateReason",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: proxy.$defined.WIDTH > 768 ? 240 : 100,
        fixed: "right",
        list: [
          {
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加素材"),
            type: "outer",
            method: () => {
              state.newPartVisible = true;
            },
          },
          {
            label: "update",
            icon: "el-icon-edit",
            show: true,
            type: "inner",
            method: (index, row) => {
              state.dialogUpdateVisible = true;
              state.updateColumns[0].value = row.id;
              state.updateColumns[1].value = row.number;
              state.updateColumns[2].value = row.name;
            },
          },
          {
            label: "historicalUpdate",
            icon: "el-icon-view",
            show: true,
            type: "inner",
            method: (index, row) => {
              proxy.$api.detection.getHistoryByPartId(row.id).then((res) => {
                res.data.map((item) => {
                  item.timestamp = item.renewalDate;
                  item.content =
                    t("i18n.updateReason") +
                    "：" +
                    item.renewalReason +
                    "（" +
                    item.createdBy +
                    "）";
                });
                state.updateHistory = res.data;
                state.dialogHistoryVisible = true;
              });
            },
          },
          {
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            type: "inner",
            method: (index, row, pages) => {
              handleDelete(row, pages);
            },
          },
          {
            label: "batchDelete",
            icon: "el-icon-delete",
            class: "import",
            show: true,
            type: "outer",
            method: () => {
              handleMulDelete(state.selectPartData);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      newPartVisible: false,
      addPartData: [],
      addPartTotal: 0,
      addPartColumns: [
        {
          prop: "name",
          label: "partName",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "replacementCycle",
          label: "replacementCycle",
          align: "center",
          row: false,
        },
        {
          prop: "remark",
          label: "remarks",
          align: "left",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (row.remark.indexOf("\n") >= 0) {
              return row.remark.replace(/\n/g, "<br/>");
            } else {
              return row.remark;
            }
          },
        },
      ],
      addPartOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addPart",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewPart();
            },
          },
        ],
      },
      addPartOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      selectNewData: [], // 选中未添加到电梯的部件
      selectPartData: [], // 选中部件数据
      dialogUpdateVisible: false,
      updateColumns: [
        {
          prop: "id",
          show: false,
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "mountingsName",
          label: "partName",
          readonly: true,
          isUpdate: true,
        },
        {
          prop: "replacementDate",
          label: "updateDate",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "renewalReason",
          label: "updateReason",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      dialogHistoryVisible: false,
      updateHistory: [],
    });

    const init = async () => {
      // 只允许连接智能系统的电梯进行部件维护
      state.loading = true;
      let elevatorId = route.query.id;
      const { data } = await proxy.$api.detection.getPartByElevator(elevatorId);
      let arr = [];
      data.map((item) => {
        arr.push({
          id: item.mountingsElevator.id,
          number: item.mountingsElevator.elevatorNumber,
          name: item.mountingsElevator.mountingsName,
          replaceCondition: item.mountings.replaceCondition,
          replacementCycle: item.mountings.replacementCycle,
          dischargeDate: item.mountingsElevator.dischargeDate,
          createdDate: item.mountingsElevator.createdDate.split(" ")[0],
          replacementDate: item.mountingsElevator.replacementDate,
          renewalReason: item.mountingsElevator.renewalReason,
        });
      });
      state.tableData = arr;
      state.total = data.total;
      state.loading = false;
    };

    const getAddPartList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.detection.getNotPartByElevator(
        id,
        params
      );
      state.addPartData = data.records;
      state.addPartTotal = data.total;
    };

    const handleDelete = (row) => {
      let ids = [row.id];
      handleMulDelete(ids);
    };

    const handleMulDelete = (ids) => {
      if (ids.length === 0) {
        proxy.$defined.tip(t("i18n.selectPart"));
        return false;
      }
      proxy.$defined.confirm(
        () => {
          proxy.$api.detection.delPartByElevator(ids).then(() => {
            init();
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    const handleSelectionChange = (val, key) => {
      let arr = [];
      val.map((item) => {
        if (key === "selectPartData") {
          arr.push(item.id);
        } else {
          arr.push({
            mountingsId: item.id,
          });
        }
      });
      state[key] = arr;
    };

    const addNewPart = async () => {
      // 新增部件到电梯
      var data = checkSelectPart("selectNewData");
      if (data.length > 0) {
        await proxy.$api.detection.addPartByElevator(route.query.id, data);
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newPartVisible = false;
        init();
      }
    };

    const checkSelectPart = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectPart"));
        return "";
      }
    };

    const onSubmit = async (row) => {
      for (let key in row) {
        if (row[key] instanceof Date) {
          row[key] = proxy.$defined.format(row[key]);
        }
      }
      await proxy.$api.detection.editPartByElevator(row.id, row);
      proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
      state.dialogUpdateVisible = false;
      init();
    };

    const onCancel = (key) => {
      state[key] = false;
    };

    return {
      ...toRefs(state),
      init,
      handleDelete,
      getAddPartList,
      handleSelectionChange,
      onSubmit,
      onCancel,
      handleMulDelete,
    };
  },
};
</script>
